<template>
  <div>
    <editor
      :api-key="apiKey"
      :init="{
        height: 500,
        min_height: 300,
        resize: 'vertical',
        menubar: 'file edit insert view format table tools help',
        toolbar:
          'undo redo | styleselect fontselect fontsizeselect| forecolor backcolor | bold italic underline | alignleft aligncenter alignright alignjustify | subscript superscript | outdent indent | link image | fullscreen | emoticons',

        images_upload_handler: uploadImageHandler,
        plugins: 'image fullscreen link searchreplace table media emoticons',
      }"
      v-model="editorContent"
      model-events="change keydown blur focus paste"
    />
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { useStore } from "vuex";

export default {
  name: "tinyMCE",
  components: {
    editor: Editor,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },

  computed: {
    editorContent: {
      get() {
        return this.value;
      },
      set(modelValue) {
        // this.value = modelValue;
        console.log(modelValue);
        this.$emit("change", modelValue);
      },
    },
  },

  data() {
    return {
      apiKey: "53rsr7259kaq87z8yyhmjjglr6j4s59e77nysfxwrl5m4ohm",
    };
  },
  setup() {
    const store = useStore();
    const getTokenUpload = async () => {
      return await store
        .dispatch("getTokenUpload", {
          class: "news",
          type: "image",
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const configUpload = {
      url: "https://upload-vm.daybreak.icu/upload/image/public",
    };

    const uploadImageHandler = async (blobInfo, success, failure, progress) => {
      const config = await getTokenUpload();
      var xhr;

      var formData = new FormData();
      formData.append("name", config.name);
      formData.append("exp", config.exp);
      formData.append("class", config.class);
      formData.append("hmac", config.hmac);
      formData.append("extra", config.extra);

      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", configUpload.url);

      xhr.upload.onprogress = function (e) {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = function () {
        var json;

        if (xhr.status === 403) {
          failure("HTTP Error: " + xhr.status, { remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure("HTTP Error: " + xhr.status);
          return;
        }

        json = JSON.parse(xhr.responseText);

        if (json.status === "success" && typeof json.url === "string") {
          success(json.url);
        }
        failure(
          "Đã xảy ra lỗi trong quá trình upload ảnh: " + xhr.responseText
        );
      };

      xhr.onerror = function () {
        failure(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      formData.append("image", blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    };

    return {
      getTokenUpload,
      uploadImageHandler,
    };
  },
};
</script>
